@charset "UTF-8";

/*
	usage:
	<a href="#" data-tooltip="This is the text of the tooltip, positioned at the bottom" data-tooltip-position="bottom">This is a link</a>

	OR

	<a href="#" data-tooltip="This is the text of the tooltip, positioned at the top">This is a link</a>

	The default position of the tooltip is TOP.
*/

[data-tooltip] {
	position: relative;
	&:before,
	&:after {
		position: absolute;
		display: none;
		opacity: .85;
	}
	&:before {
		content: attr(data-tooltip);
		background: color(black);
		color: color(black);
		font-size: 13px;
		padding: 5px;
		border-radius: 5px;
		white-space: nowrap;
		text-decoration: none;
		bottom: 100%;
		left: 0;
		margin-bottom: 10px;
	}
	&:after {
		content: "";
		width: 0;
		height: 0;
		border: 6px solid transparent;
		border-top-color: color(black);
		border-bottom: none;
		bottom: 100%;
		left: 10px;
		margin-bottom: 4px;
	}
	&:hover {
		&:before,
		&:after {
			display: block;
		}
	}
	[data-tooltip-position="bottom"] {
		&:before {
			top: 100%;
			left: 0;
			margin-top: 10px;
		}
		&:after {
			border-bottom-color: color(black);
			border-top: none;
			top: 100%;
			left: 10px;
			margin-top: 4px;
		}
	}
	[data-tooltip-position="left"] {
		&:before {
			top: -25%;
			right: 100%;
			margin-right: 10px;
		}
		&:after {
			border-left-color: color(black);
			border-right: none;
			top: 50%;
			right: 100%;
			margin-top: -6px;
			margin-right: 4px;
		}
	}
	[data-tooltip-position="right"] {
		&:before {
			top: -25%;
			left: 100%;
			margin-left: 10px;
		}
		&:after {
			border-right-color: color(black);
			border-left: none;
			top: 50%;
			left: 100%;
			margin-top: -6px;
			margin-left: 4px;
		}
	}
}
