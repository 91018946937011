@charset "UTF-8";

/*
  When you're using .grid-row and .grid-column-*, make sure to copy paste the line(s) below and change it/them when needed

  --gridGutter: 10px;
 */

@mixin grid-row {
  display: flex;
  flex-wrap: wrap;
  //justify-content: center;
  width: calc(100% + ( 1 * var(--gridGutter) ) );
  margin: {
    left: calc( -1 * (var(--gridGutter) / 2));
  }
}
.grid-row {
  --gridGutter: 20px;
  @include grid-row();
}

[class*="grid-column-mobile"] {
  display: inline-block;
  flex-direction: column;
}

@for $i from 0 through 19 {
  $classname: $i*10/2;
  @if $classname < 10 {
    $classname: "0" + $classname;
  }
  $i: $i*5;
  .grid-column-mobile_#{$classname} {
    width: calc(#{$i * 1%} - var(--gridGutter));
    margin: {
      right: calc(var(--gridGutter) / 2);
      bottom: var(--gridGutter);
      left: calc(var(--gridGutter) / 2);
    }
  }
  .grid-column-mobile_33 {
    width: calc( (100% / 3) - var(--gridGutter) );
    margin: {
      right: calc(var(--gridGutter) / 2);
      bottom: var(--gridGutter);
      left: calc(var(--gridGutter) / 2);
    }
  }
  .grid-column-mobile_100 {
    width: 100%;
    margin: {
      right: calc(var(--gridGutter) / 2);
      bottom: var(--gridGutter);
      left: calc(var(--gridGutter) / 2);
    }
  }
}

@include bp(m) {
  [class*="grid-column-tabletPortrait"] {
    display: inline-block;
    flex-direction: column;
  }
  @for $i from 0 through 19 {
    $classname: $i*10/2;
    @if $classname < 10 {
      $classname: "0" + $classname;
    }
    $i: $i*5;
    .grid-column-tabletPortrait_#{$classname} {
      width: calc(#{$i * 1%} - var(--gridGutter));
      margin: {
        right: calc(var(--gridGutter) / 2);
        bottom: var(--gridGutter);
        left: calc(var(--gridGutter) / 2);
      }
    }
    .grid-column-tabletPortrait_33 {
      width: calc( (100% / 3) - var(--gridGutter) );
      margin: {
        right: calc(var(--gridGutter) / 2);
        bottom: var(--gridGutter);
        left: calc(var(--gridGutter) / 2);
      }
    }
    .grid-column-tabletPortrait_100 {
      width: 100%;
      margin: {
        right: calc(var(--gridGutter) / 2);
        bottom: var(--gridGutter);
        left: calc(var(--gridGutter) / 2);
      }
    }
  }
}

@include bp(l) {
  [class*="grid-column-tabletLandscape"] {
    display: inline-block;
    flex-direction: column;
  }
  @for $i from 0 through 19 {
    $classname: $i*10/2;
    @if $classname < 10 {
      $classname: "0" + $classname;
    }
    $i: $i*5;
    .grid-column-tabletLandscape_#{$classname} {
      width: calc(#{$i * 1%} - var(--gridGutter));
      margin: {
        right: calc(var(--gridGutter) / 2);
        bottom: var(--gridGutter);
        left: calc(var(--gridGutter) / 2);
      }
    }
    .grid-column-tabletLandscape_33 {
      width: calc( (100% / 3) - var(--gridGutter) );
      margin: {
        right: calc(var(--gridGutter) / 2);
        bottom: var(--gridGutter);
        left: calc(var(--gridGutter) / 2);
      }
    }
    .grid-column-tabletLandscape_100 {
      width: 100%;
      margin: {
        right: calc(var(--gridGutter) / 2);
        bottom: var(--gridGutter);
        left: calc(var(--gridGutter) / 2);
      }
    }
  }
}

@include bp(xl) {
  [class*="grid-column-desktop"] {
    display: inline-block;
    flex-direction: column;
  }
  @for $i from 0 through 19 {
    $classname: $i*10/2;
    @if $classname < 10 {
      $classname: "0" + $classname;
    }
    $i: $i*5;
    .grid-column-desktop_#{$classname} {
      width: calc(#{$i * 1%} - var(--gridGutter));
      margin: {
        right: calc(var(--gridGutter) / 2);
        bottom: var(--gridGutter);
        left: calc(var(--gridGutter) / 2);
      }
    }
    .grid-column-desktop_33 {
      width: calc( (100% / 3) - var(--gridGutter) );
      margin: {
        right: calc(var(--gridGutter) / 2);
        bottom: var(--gridGutter);
        left: calc(var(--gridGutter) / 2);
      }
    }
    .grid-column-desktop_100 {
      width: 100%;
      margin: {
        right: calc(var(--gridGutter) / 2);
        bottom: var(--gridGutter);
        left: calc(var(--gridGutter) / 2);
      }
    }
  }
}
