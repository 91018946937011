@charset "UTF-8";

.block {
	color: color(grey);
	font: {
		size: 18px;
	}
	&.color-light {
		background: {
			color: #f9f6f3;
		}
		.note {
			background: {
				color: #f1e7de;
			}
		}
	}
	&.color-dark {
		background: {
			color: #f1e7de;
		}
		.note {
			background: {
				color: #f9f6f3;
			}
		}
	}
	img {
		display: block;
		margin: {
			top: 12px;
			right: auto;
			bottom: 24px;
			left: auto;
		}
		max-width: 200px;
	}
	hr {
		margin: {
			right: auto;
			left: auto;
		}
		max-width: 90%;
		border: {
			width: 0 0 1px;
			image: linear-gradient(90deg, rgba(color(grey), 0), color(grey) 50%, rgba(color(grey), 0) 100%) 0 0 100%;
			style: solid;
		}
		color: color(red);
		background-position: 50%;
		margin: {
			bottom: var(--gridGutter);
		}
	}
	.text-rules {
		font: {
			size: 14px;
		}
	}
	.link-discord {
		svg {
			display: block;
			margin: {
				top: 40px;
				right: auto;
				left: auto;
			}
			width: 75%;
			max-width: 400px;
		}
	}
	.note {
		//display: block;
		padding: 10px;
		border: {
			width: 1px;
			style: solid;
			color: darken(#f1e7de, 15%);
		}
	}
}

@include bp(m) {
	.block-rules {
		.wrapper-inner {
			> .grid-row {
				--gridGutter: 100px;
			}
		}
		.text-intro {
			text: {
				//align: justify;
			}
		}
	}
}