@charset "UTF-8";

.header-main {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background: {
			image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
		}
	}
	img {
		position: absolute;
		bottom: 75px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
		width: 65%;
		max-width: 600px;
	}
	video {
		width: 100%;
		height: 100%;
	}
	.video-desktop {
		display: none;
	}
	.mouse {
		position: absolute;
		bottom: 12px;
		left: 50%;
		z-index: 3;
		width: 30px;
		height: 55px;
		backface-visibility: hidden;
		transform: translateX(-50%) scale(.65, .65);
		border: {
			width: 2px;
			style: solid;
			color: color(yellow);
			radius: 26px;
		}
		&:after {
			background-color: color(yellow);
			border-radius: 100%;
			content: "";
			position: absolute;
			top: 10px;
			left: 50%;
			margin-left: -2px;
			width: 5px;
			height: 5px;
			opacity: 1;
			-webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
			transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
			-webkit-animation: scroll 1.5s -1s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
			animation: scroll 1.5s -1s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
		}
	}
}

@include bp(m) {
	.header-main {
		.mouse {
			bottom: 24px;
			transform: translateX(-50%);
		}
	}
}

@include bp(l) {
	.header-main {
		.video-mobile {
			display: none;
		}
		.video-desktop {
			display: block;
		}
	}
}