@charset "UTF-8";

$breakpoints: map-deep-get($boilerplate-config, "breakpoints");

@mixin bp($screensize) {
	@if map-has-key($breakpoints, $screensize) {
		@media screen and (min-width: map-get($breakpoints, $screensize)) {
			@content;
		}
	} @else {
		@media screen and (min-width: #{$screensize}) {
			@content;
		}
	}
}

$breakpoint-initial: map-deep-get($boilerplate-config, "breakpoints", "s");
@media screen and (max-width: $breakpoint-initial) {
	body {
		&:before {
			content: "mobile";
			display: none;
		}
	}
}

@each $breakpoint, $screensize in $breakpoints {
	@include bp(#{$screensize}) {
		body {
			&::before {
				@if $breakpoint == l or $breakpoint == xl or $breakpoint == xxl or $breakpoint == xxxl {
					content: "desktop";
				} @else if $breakpoint == m {
					content: "tablet";
				} @else {
					content: "mobile";
				}
				display: none;
			}
		}
	}
}
