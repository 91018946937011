@charset "UTF-8";

.wrapper-main {

}

.wrapper-inner {
	--paddingBody: 15px;

	width: 100%;
	max-width: 1440px;
	margin: {
		right: auto;
		left: auto;
	}
	padding: {
		right: var(--paddingBody);
		left: var(--paddingBody);
	}
}

[class*="block"] {
	padding: {
		top: 40px;
		bottom: 40px;
	}
}

.scroll-indicator {
	position: fixed;
	top: 10px;
	right: 5px;
	bottom: 50px;
	z-index: 999;
	margin: {
		//bottom: 10px;
	}
	width: 2px;
	&:before {
		content: "";
		position: fixed;
		top: 10px;
		right: 5px;
		bottom: 10px;
		width: 2px;
		background: color(grey);
	}
	.indicator {
		position: absolute;
		top: 0;
		left: -4px;
		width: 10px;
		height: 50px;
		border: {
			radius: 5px;
		}
		background: {
			color: color(red);
		}
		span {
			display: inline-block;
			position: absolute;
			top: 50%;
			transform: translate(-125%, -50%);
			height: 16px;
			line-height: 17px;
			padding: {
				right: 8px;
				left: 8px;
			}
			border: {
				radius: 8px;
			}
			color: color(grey);
			font: {
				size: 10px;
				weight: bold;
			}
			background: {
				color: color(yellow);
			}
		}
	}
}


@keyframes scroll {
	0%,
	20% {
		transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
	}
	10% {
		transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
		opacity: 1;
	}
	100% {
		transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
		opacity: .01;
	}
}

@include bp(m) {
	.wrapper-inner {
		--paddingBody: 10px;
	}
	[class*="block-"] {
		padding: {
			top: 50px;
			bottom: 50px;
		}
	}
}

@include bp(l) {
	[class*="block-"] {
		padding: {
			top: 50px;
			bottom: 50px;
		}
	}
}

@include bp(xl) {
	.wrapper-inner {
		--paddingBody: 20px;
	}
}
