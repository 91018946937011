@media screen and (max-width: 480px) {
  body:before {
    content: "mobile";
    display: none; } }

@media screen and (min-width: 480px) {
  body::before {
    content: "mobile";
    display: none; } }

@media screen and (min-width: 768px) {
  body::before {
    content: "tablet";
    display: none; } }

@media screen and (min-width: 1024px) {
  body::before {
    content: "desktop";
    display: none; } }

@media screen and (min-width: 1280px) {
  body::before {
    content: "desktop";
    display: none; } }

@media screen and (min-width: 1440px) {
  body::before {
    content: "desktop";
    display: none; } }

@media screen and (min-width: 2048px) {
  body::before {
    content: "desktop";
    display: none; } }

/*
	usage:

	.element {
		@include browserfix(ie) {
			&:after {
				content: "This is IE only"
			}
		}
		@include browserfix(firefox) {
			&:after {
				content: "Only Firefox users will see this";
			}
		}
	}
*/
/*
  When you're using .grid-row and .grid-column-*, make sure to copy paste the line(s) below and change it/them when needed

  --gridGutter: 10px;
 */
.grid-row {
  --gridGutter: 20px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + ( 1 * var(--gridGutter) ));
  margin-left: calc( -1 * (var(--gridGutter) / 2)); }

[class*="grid-column-mobile"] {
  display: inline-block;
  flex-direction: column; }

.grid-column-mobile_00 {
  width: calc(0% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_05 {
  width: calc(5% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_10 {
  width: calc(10% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_15 {
  width: calc(15% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_20 {
  width: calc(20% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_25 {
  width: calc(25% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_30 {
  width: calc(30% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_35 {
  width: calc(35% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_40 {
  width: calc(40% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_45 {
  width: calc(45% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_50 {
  width: calc(50% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_55 {
  width: calc(55% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_60 {
  width: calc(60% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_65 {
  width: calc(65% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_70 {
  width: calc(70% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_75 {
  width: calc(75% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_80 {
  width: calc(80% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_85 {
  width: calc(85% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_90 {
  width: calc(90% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_95 {
  width: calc(95% - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_33 {
  width: calc( (100% / 3) - var(--gridGutter));
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

.grid-column-mobile_100 {
  width: 100%;
  margin-right: calc(var(--gridGutter) / 2);
  margin-bottom: var(--gridGutter);
  margin-left: calc(var(--gridGutter) / 2); }

@media screen and (min-width: 768px) {
  [class*="grid-column-tabletPortrait"] {
    display: inline-block;
    flex-direction: column; }
  .grid-column-tabletPortrait_00 {
    width: calc(0% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_05 {
    width: calc(5% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_10 {
    width: calc(10% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_15 {
    width: calc(15% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_20 {
    width: calc(20% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_25 {
    width: calc(25% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_30 {
    width: calc(30% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_35 {
    width: calc(35% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_40 {
    width: calc(40% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_45 {
    width: calc(45% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_50 {
    width: calc(50% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_55 {
    width: calc(55% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_60 {
    width: calc(60% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_65 {
    width: calc(65% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_70 {
    width: calc(70% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_75 {
    width: calc(75% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_80 {
    width: calc(80% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_85 {
    width: calc(85% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_90 {
    width: calc(90% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_95 {
    width: calc(95% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletPortrait_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); } }

@media screen and (min-width: 1024px) {
  [class*="grid-column-tabletLandscape"] {
    display: inline-block;
    flex-direction: column; }
  .grid-column-tabletLandscape_00 {
    width: calc(0% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_05 {
    width: calc(5% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_10 {
    width: calc(10% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_15 {
    width: calc(15% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_20 {
    width: calc(20% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_25 {
    width: calc(25% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_30 {
    width: calc(30% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_35 {
    width: calc(35% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_40 {
    width: calc(40% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_45 {
    width: calc(45% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_50 {
    width: calc(50% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_55 {
    width: calc(55% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_60 {
    width: calc(60% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_65 {
    width: calc(65% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_70 {
    width: calc(70% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_75 {
    width: calc(75% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_80 {
    width: calc(80% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_85 {
    width: calc(85% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_90 {
    width: calc(90% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_95 {
    width: calc(95% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-tabletLandscape_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); } }

@media screen and (min-width: 1280px) {
  [class*="grid-column-desktop"] {
    display: inline-block;
    flex-direction: column; }
  .grid-column-desktop_00 {
    width: calc(0% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_05 {
    width: calc(5% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_10 {
    width: calc(10% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_15 {
    width: calc(15% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_20 {
    width: calc(20% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_25 {
    width: calc(25% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_30 {
    width: calc(30% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_35 {
    width: calc(35% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_40 {
    width: calc(40% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_45 {
    width: calc(45% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_50 {
    width: calc(50% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_55 {
    width: calc(55% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_60 {
    width: calc(60% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_65 {
    width: calc(65% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_70 {
    width: calc(70% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_75 {
    width: calc(75% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_80 {
    width: calc(80% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_85 {
    width: calc(85% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_90 {
    width: calc(90% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_95 {
    width: calc(95% - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_33 {
    width: calc( (100% / 3) - var(--gridGutter));
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); }
  .grid-column-desktop_100 {
    width: 100%;
    margin-right: calc(var(--gridGutter) / 2);
    margin-bottom: var(--gridGutter);
    margin-left: calc(var(--gridGutter) / 2); } }

/* http://meyerweb.com/eric/tools/css/reset/ 
	 v2.0 | 20110126
	 License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*:before,
*:after {
  box-sizing: inherit;
  font-smoothing: inherit;
  outline: none; }

html,
body {
  width: 100%;
  overflow-x: hidden;
  /* prevents horizontal scrollbars */ }

html {
  height: 100%;
  box-sizing: border-box; }

body {
  position: relative;
  color: #fff;
  background-color: #f9f6f3;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  font-family: "dutchfurballsbody", Helvetica, Arial, sans-serif;
  font-smoothing: antialiased;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

::focus:not(:focus-visible) {
  /* https://twitter.com/LeaVerou/status/1045768279753666562 */
  outline: none; }

::selection {
  background: #000;
  color: #fff; }

h2,
h3 {
  font-family: "dutchfurballsheading", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }

h2 {
  margin-bottom: 25px;
  font-size: 28px; }

h3 {
  margin-bottom: 15px;
  font-size: 22px; }

a,
a:link,
a:visited,
a:active {
  color: #ff5b3b;
  font-weight: bold;
  text-decoration: none; }

p {
  margin-bottom: 1.5em;
  margin-top: 0;
  line-height: 1.5em; }
  p:last-of-type {
    margin-bottom: 0; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

img,
video {
  /* you want these elements fluid, probably... */
  max-width: 100%;
  height: auto; }

figure {
  margin: 0; }

code {
  font-family: Monaco;
  outline: none; }

abbr {
  position: relative;
  border-bottom: none;
  text-decoration: none;
  cursor: help; }
  abbr:after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    border-bottom-color: currentColor;
    transition: all .25s ease-out; }
  abbr:hover:after {
    bottom: -2px; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  * {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
    font-smoothing: always; } }

@font-face {
  font-family: "dutchfurballsbody";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/bodyfont-regular.ttf") format("truetype"), url("../fonts/bodyfont-regular.woff2") format("woff2"), url("../fonts/bodyfont-regular.woff") format("woff"); }

@font-face {
  font-family: "dutchfurballsbody";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/bodyfont-bold.ttf") format("truetype"), url("../fonts/bodyfont-bold.woff2") format("woff2"), url("../fonts/bodyfont-bold.woff") format("woff"); }

@font-face {
  font-family: "dutchfurballsheading";
  font-style: regular;
  font-weight: normal;
  src: url("../fonts/headingfont-regular.ttf") format("truetype"), url("../fonts/headingfont-regular.woff2") format("woff2"), url("../fonts/headingfont-regular.woff") format("woff"); }

.block {
  color: #665c58;
  font-size: 18px; }
  .block.color-light {
    background-color: #f9f6f3; }
    .block.color-light .note {
      background-color: #f1e7de; }
  .block.color-dark {
    background-color: #f1e7de; }
    .block.color-dark .note {
      background-color: #f9f6f3; }
  .block img {
    display: block;
    margin-top: 12px;
    margin-right: auto;
    margin-bottom: 24px;
    margin-left: auto;
    max-width: 200px; }
  .block hr {
    margin-right: auto;
    margin-left: auto;
    max-width: 90%;
    border-width: 0 0 1px;
    border-image: linear-gradient(90deg, rgba(102, 92, 88, 0), #665c58 50%, rgba(102, 92, 88, 0) 100%) 0 0 100%;
    border-style: solid;
    color: #ff5b3b;
    background-position: 50%;
    margin-bottom: var(--gridGutter); }
  .block .text-rules {
    font-size: 14px; }
  .block .link-discord svg {
    display: block;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    width: 75%;
    max-width: 400px; }
  .block .note {
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #dac0a8; }

@media screen and (min-width: 768px) {
  .block-rules .wrapper-inner > .grid-row {
    --gridGutter: 100px; } }

.header-main {
  position: relative; }
  .header-main:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%); }
  .header-main img {
    position: absolute;
    bottom: 75px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 65%;
    max-width: 600px; }
  .header-main video {
    width: 100%;
    height: 100%; }
  .header-main .video-desktop {
    display: none; }
  .header-main .mouse {
    position: absolute;
    bottom: 12px;
    left: 50%;
    z-index: 3;
    width: 30px;
    height: 55px;
    backface-visibility: hidden;
    transform: translateX(-50%) scale(0.65, 0.65);
    border-width: 2px;
    border-style: solid;
    border-color: #f9f6f3;
    border-radius: 26px; }
    .header-main .mouse:after {
      background-color: #f9f6f3;
      border-radius: 100%;
      content: "";
      position: absolute;
      top: 10px;
      left: 50%;
      margin-left: -2px;
      width: 5px;
      height: 5px;
      opacity: 1;
      -webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
      transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
      -webkit-animation: scroll 1.5s -1s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
      animation: scroll 1.5s -1s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite; }

@media screen and (min-width: 768px) {
  .header-main .mouse {
    bottom: 24px;
    transform: translateX(-50%); } }

@media screen and (min-width: 1024px) {
  .header-main .video-mobile {
    display: none; }
  .header-main .video-desktop {
    display: block; } }

.footer-main img {
  width: 100%; }

.wrapper-inner {
  --paddingBody: 15px;
  width: 100%;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--paddingBody);
  padding-left: var(--paddingBody); }

[class*="block"] {
  padding-top: 40px;
  padding-bottom: 40px; }

.scroll-indicator {
  position: fixed;
  top: 10px;
  right: 5px;
  bottom: 50px;
  z-index: 999;
  width: 2px; }
  .scroll-indicator:before {
    content: "";
    position: fixed;
    top: 10px;
    right: 5px;
    bottom: 10px;
    width: 2px;
    background: #665c58; }
  .scroll-indicator .indicator {
    position: absolute;
    top: 0;
    left: -4px;
    width: 10px;
    height: 50px;
    border-radius: 5px;
    background-color: #ff5b3b; }
    .scroll-indicator .indicator span {
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translate(-125%, -50%);
      height: 16px;
      line-height: 17px;
      padding-right: 8px;
      padding-left: 8px;
      border-radius: 8px;
      color: #665c58;
      font-size: 10px;
      font-weight: bold;
      background-color: #f9f6f3; }

@keyframes scroll {
  0%,
  20% {
    transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px); }
  10% {
    transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
    opacity: 1; }
  100% {
    transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
    opacity: .01; } }

@media screen and (min-width: 768px) {
  .wrapper-inner {
    --paddingBody: 10px; }
  [class*="block-"] {
    padding-top: 50px;
    padding-bottom: 50px; } }

@media screen and (min-width: 1024px) {
  [class*="block-"] {
    padding-top: 50px;
    padding-bottom: 50px; } }

@media screen and (min-width: 1280px) {
  .wrapper-inner {
    --paddingBody: 20px; } }

label,
select,
option,
button {
  cursor: pointer; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

[data-clickbox] {
  cursor: pointer; }

/*
	usage:
	<a href="#" data-tooltip="This is the text of the tooltip, positioned at the bottom" data-tooltip-position="bottom">This is a link</a>

	OR

	<a href="#" data-tooltip="This is the text of the tooltip, positioned at the top">This is a link</a>

	The default position of the tooltip is TOP.
*/
[data-tooltip] {
  position: relative; }
  [data-tooltip]:before, [data-tooltip]:after {
    position: absolute;
    display: none;
    opacity: .85; }
  [data-tooltip]:before {
    content: attr(data-tooltip);
    background: #000;
    color: #000;
    font-size: 13px;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    text-decoration: none;
    bottom: 100%;
    left: 0;
    margin-bottom: 10px; }
  [data-tooltip]:after {
    content: "";
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #000;
    border-bottom: none;
    bottom: 100%;
    left: 10px;
    margin-bottom: 4px; }
  [data-tooltip]:hover:before, [data-tooltip]:hover:after {
    display: block; }
  [data-tooltip] [data-tooltip-position="bottom"]:before {
    top: 100%;
    left: 0;
    margin-top: 10px; }
  [data-tooltip] [data-tooltip-position="bottom"]:after {
    border-bottom-color: #000;
    border-top: none;
    top: 100%;
    left: 10px;
    margin-top: 4px; }
  [data-tooltip] [data-tooltip-position="left"]:before {
    top: -25%;
    right: 100%;
    margin-right: 10px; }
  [data-tooltip] [data-tooltip-position="left"]:after {
    border-left-color: #000;
    border-right: none;
    top: 50%;
    right: 100%;
    margin-top: -6px;
    margin-right: 4px; }
  [data-tooltip] [data-tooltip-position="right"]:before {
    top: -25%;
    left: 100%;
    margin-left: 10px; }
  [data-tooltip] [data-tooltip-position="right"]:after {
    border-right-color: #000;
    border-left: none;
    top: 50%;
    left: 100%;
    margin-top: -6px;
    margin-left: 4px; }
