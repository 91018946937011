@charset "UTF-8";

label,
select,
option,
button {
	cursor: pointer;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
	legend {

	}
}

label {

}

//#{$all-text-inputs},
//textarea {
//
//	@include placeholder() {
//
//	}
//}
//
//#{$all-text-inputs} {
//
//}
//
//textarea {
//	resize: vertical;
//}
//
//#{$all-button-inputs} {
//
//}
//
//#{$all-button-inputs-hover},
//#{$all-button-inputs-focus} {
//
//}
