@charset "UTF-8";

*,
*:before,
*:after {
	box: {
		sizing: inherit;
	}
	font: {
		smoothing: inherit;
	}
	outline: none;
}

html,
body {
	width: 100%;
	overflow-x: hidden; /* prevents horizontal scrollbars */
}

html {
	height: 100%;
	box: {
		sizing: border-box;
	}
}

body {
	position: relative;
	color: color(white);
	background: {
		color: color(yellow);
	}
	text: {
		rendering: optimizeLegibility;
		size-adjust: 100%;
	}
	font: {
		family: font(body);
		smoothing: antialiased;
	}
	//hyphens: auto; /* https://twitter.com/pugson/status/1034889153849307141 */
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: transparent;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::focus:not(:focus-visible) { /* https://twitter.com/LeaVerou/status/1045768279753666562 */
	outline: none;
}

::selection {
	background: color(black);
	color: color(white);
}

h2,
h3 {
	font: {
		family: font(heading);
	}
	text: {
		transform: uppercase;
	}
}

h2 {
	margin: {
		bottom: 25px;
	}
	font: {
		size: 28px;
	}
}

h3 {
	margin: {
		bottom: 15px;
	}
	font: {
		size: 22px;
	}
}


@include bp(m) {
	h2 {

	}
	h3 {

	}
}


a,
a:link,
a:visited,
a:active {
	color: color(red);
	font: {
		weight: bold;
	}
	text: {
		decoration: none;
	}
}

p {
	margin: {
		bottom: 1.5em;
		top: 0;
	}
	line-height: 1.5em;
	&:last-of-type {
		margin: {
			bottom: 0;
		}
	}
}

strong {
	font: {
		weight: bold;
	}
}

em {
	font: {
		style: italic;
	}
}

img,
video { /* you want these elements fluid, probably... */
	max-width: 100%;
	height: auto;
}

figure {
	margin: 0;
}

code {
	font: {
		family: Monaco;
	}
	outline: none;
}

abbr {
	position: relative;
	border: {
		bottom: none;
	}
	text: {
		decoration: none;
	}
	cursor: help;
	&:after {
		content: "";
		position: absolute;
		bottom: -4px;
		left: 0;
		width: 100%;
		height: 1px;
		border: {
			bottom: {
				width: 1px;
				style: dotted;
				color: currentColor;
			}
		}
		transition: all .25s ease-out;
	}
	&:hover {
		&:after {
			bottom: -2px;
		}
	}
}

@include browserfix(ie) {
	* {
		text: {
			shadow: 1px 1px 1px rgba(color(black),.08);
		}
		font: {
			smoothing: always;
		}
	}
}
