@charset "UTF-8";

$boilerplate-config: (
	debug-breakpoints                 : 0,
	debug-outlines                    : 0,
	breakpoints: (
		s                             : 480px,    /* iPhone 4 in landscape */
		m                             : 768px,    /* iPad portrait */
		l                             : 1024px,   /* iPad landscape, most 15" displays */
		xl                            : 1280px,   /* bigger screens */
		xxl                           : 1440px,   /* ultra big ass screens */
		xxxl                          : 2048px,   /* rich tata screens */
	),
	coloring: (
		debug                         : #f00,
		black                         : #000,
		white                         : #fff,
		blue                          : #5ea3c2,
		yellow                        : #f9f6f3,
		grey                          : #665c58,
		red                           : #ff5b3b,
	),
	fonts: (
		body                          : ("dutchfurballsbody", Helvetica, Arial, sans-serif),
		heading                       : ("dutchfurballsheading", Helvetica, Arial, sans-serif),
	),
	opacity: (
		light                         : .8, /* opacity used with lighter colors */
		dark                          : .4  /* opacity used with darker colors */
	)
);
